@import '../node_modules/bootstrap/scss/bootstrap';

$primary: #031426;
$blue: #0b4480;
$blue-mid: #1580ef;
$blue-light: #98cafd;
$accent-blue: #5d62b5;
$accent-opal: #29c3be;
$accent-red: #fd6561;
$accent-yellow: #ffc533;
$accent-green: #62b58f;
$accent-purple: #bc95df;

* {
  margin: 0;
  font-family: 'Poppins', sans-serif;

  html,
  body {
    scroll-behavior: smooth !important;
  }
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

button[disabled='disabled'],
button:disabled {
  color: #d3d3d3 !important;
  cursor: not-allowed;
  pointer-events: inherit !important;
}

.cluster-map {
  z-index: 9999;
}
.quicksight-embedding-iframe {
  height: inherit !important;
}

@media only screen and (max-width: 1600px) {
  .quicksight-custom {
    height: 85vh !important;
  }
}

.rdt_TableCol_Sortable {
  span {
    font-size: 10px;
    margin-left: 2px;
  }
}

.rdt_Pagination {
  border-top: none !important;
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    display: flex !important;
    align-items: center !important;
    // width: 270px !important;
    .react-datepicker__calendar-icon {
      width: 24px;
      height: 24px;
      padding-left: 8px;
    }
    .react-datepicker__close-icon {
      padding: 0 15px 0 0;
      &::after {
        background-color: #667085;
        border-radius: 5px;
        width: 15px;
        height: 15px;
      }
    }
  }
}
