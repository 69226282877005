.report-part {
  .section-title {
    line-height: 48px;
    background-color: #fff;
    border-style: solid;
    border-color: #c6ccd9;
    border-width: 0 0 1px 0;
    font-weight: 600;
  }
  &__content {
    margin: 32px 36px;

    .progress-bar-overall {
      height: 32px;
      border-radius: 8px;
    }

    .overall-indicator {
      p,
      span {
        font-size: 16px;
      }
      p {
        font-weight: 600;
        color: #222222;
      }
      span {
        font-weight: 400;
        color: #8a8a8a;
      }
    }
  }
}
