.routific-wrapper {
    padding: 24px 20px 20px 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .igr-custom-input {
    border-color: #EAECF0 !important;
    border-left: none;
    font-size: 14px;
    font-weight: 400;
    color: #4C697F;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    &:focus {
      box-shadow: unset !important;
      border-color: #EAECF0 !important;
    }
    &::placeholder {
      color: #BEBEBF !important
    }
  }
  
  .igr-custom-icon {
    border-color: #EAECF0 !important;
    background-color: transparent !important;
    padding-right: 0px !important;
    // border-right: none !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  
  .datepicker-custom {
    border-color: #EAECF0 !important;
    padding: 8px 15px 8px 40px !important;
    font-size: 14px;
    font-weight: 400;
    color: #4C697F;
    &:focus {
      box-shadow: unset !important;
      border-color: #EAECF0 !important;
    }
  }
  
  // .custom-datepicker-wrapper {
  //   .react-datepicker-wrapper {
  //     .react-datepicker__input-container {
  //       display: flex !important;
  //       align-items: center !important;
  //       width: 260px !important;
  //     }
  //     .react-datepicker__calendar-icon {
  //       width: 16px;
  //       height: 16px;
  //     }
  //   }
  // }
  
  .igr-calendar-input {
    border-color: #EAECF0 !important;
    border-left: none;
    font-size: 14px;
    font-weight: 400;
    color: #4C697F;
    &:focus {
      box-shadow: unset !important;
      border-color: #EAECF0 !important;
    }
    &::placeholder {
      color: #BEBEBF !important;
    }
  }
  