.checkbox-button {
  .checkbox-label {
    font-weight: 400;
    font-size: 14px;
    width: 90%;
  }
  input[type='checkbox'] {
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: #ffffff;
    border: 1px solid #98A1B5;
    accent-color: #4c697f;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    appearance: checkbox;
  }
}
