@import '../../common/styles/common.scss';

.container {
  .left-side {
    p {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .right-side {
    img {
      width: 16px;
    }
    p {
      font-size: 12px;
    }
    .text {
      color: #222222;
      @include textLine(3);
    }

    .status-line {
      border-left: 2px dashed #eaecf0;
      height: 100%;
    }
    .detail-icon {
      width: 16px;
      height: 16px;
    }
  }
}
