@import '../../../common/styles/common.scss';

.modal {
  &-content {
    height: 100%;
  }

  &-header {
    background-color: #4c697f;
    height: 48px;

    &__title {
      font-size: 14px;
      font-weight: 400;
      @include textLine(1);

      span {
        font-weight: 600;
      }
    }
  }
  &-body {
    background-color: #f4f6f6;
    overflow-x: hidden;
    overflow-y: scroll !important;

    .section-title {
      line-height: 48px;
      background-color: #fff;
      font-size: 16px;
    }

    .circle-icon {
      width: 10px;
      height: 10px;
    }
  }
}
