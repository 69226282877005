@import '../../common/styles/common.scss';

.report-item {
  &__mark {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    @include textLine(1);
  }
  &__percentage {
    font-weight: 600;
    font-size: 16px;
    color: #222222;
  }
  .progress-bar-container {
    border-radius: 6px;
    background-color: #62b58f;
    height: 24px;
  }
}
.break-line {
  border-top: 1px dashed rgba(34, 34, 34, 0.2);
}
