@import '../../common/styles/common.scss';

.search-box {
  right: 8px;
  z-index: 999;
  border: 1px solid #EAECF0;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3);

  .search-box-container {
    background-color: #fff;
    width: calc(100% - 24px);
    margin: 0 12px;
    border-radius: 4px;
  }

  .result-list {
    .result-item {
      width: 100%;
      cursor: pointer;
      &__txt {
        font-weight: 500;
        font-size: 12px;
        color: #101828;
        @include textLine(1);
        display: inline-block;
        white-space: nowrap;
      }
      &__time_label {
        background: #e9e9e9;
        border-radius: 2px;
        padding: 1px 4px;
        height: 16px;
        p {
          font-weight: 500;
          font-size: 10px;
        }
      }
      &__address {
        @include textLine(1);
        display: inline-block;
        white-space: nowrap;
        font-weight: 400;
        font-size: 12px;
        color: #8a8a8a;
      }
    }
    &__header {
      height: 24px;
      border: 1px solid #EAECF0;
      background: #F9F9F9;
    }
    &__title {
      font-weight: 500;
      font-size: 12px;
    }
    &__count {
      font-weight: 400;
      font-size: 12px;
    }
    &__content {
      display: flex;
      overflow-y: auto;
      width: 100%;
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #BEBEBF;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #BEBEBF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #BEBEBF;
  }
}
