@import '../../common/styles/common.scss';

.container {
  .header {
    max-height: 60px;
    height: 60px;
    border-bottom: 1px solid #f2f4f7;
    &__title {
      font-size: 24px;
      font-weight: 700;
      @include textLine(1);
    }
  }
  .panel-control {
    bottom: 32px;
    right: 8px;

    &__zoom {
      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      margin-bottom: 8px;
    }
  }

  // .route-list,
  // .map-container {
  //   -moz-transition: height 0.5s;
  //   -ms-transition: height 0.5s;
  //   -o-transition: height 0.5s;
  //   -webkit-transition: height 0.5s;
  //   transition: height 0.5s;
  // }

  .route-list {
    background-color: #f4f6f6;

    &__header {
      background-color: white;
      min-height: 60px;
      border-width: 2px 0px;
      border-style: solid;
      border-color: #f2f4f7;
      box-sizing: border-box;

      .title-left {
        font-size: 20px;
        font-weight: 500;
      }
      .sort-by {
        font-weight: 400;
        font-size: 12px;
        color: #4c697f;
      }
      .menu-config-txt {
        font-weight: 400;
        font-size: 14px;
      }
    }
    &__content {
      height: 100%;
    }
  }
  // General
  button {
    background-color: white;
  }

  .custom-btn {
    margin-right: 10px;
  }

  .stop-detail {
    right: 8px;
    background: white;
    z-index: 999;
    width: 352px;
    border: 1px solid #EAECF0;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.3);
  }
}

.tooltip-container {
  opacity: 1 !important;
  p {
    color: #000;
  }
  div[class^='tooltip-arrow']::before {
    display: none;
  }
  div[class^='tooltip-inner'] {
    display: flex !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background-color: white;
    width: 336px;
    max-width: 336px;
    min-height: 188px;
    margin-top: 4px;
    padding: 16px;
  }
  .tooltip-title {
    font-size: 16px;
    font-weight: 500;
  }
  .tooltip-label {
    font-weight: 500;
    font-size: 14px;
    color: #bebebf;
  }
  .tooltip-value {
    font-weight: 500;
    font-size: 14px;
    color: #222222;
  }
}
