.custom-pagination-button {
  border-radius: 6px;
  border: 1px solid #4C697F;
  // width: 80px;
  height: 28px;
  color: #4C697F;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-size: 14px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
