.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 28px;
  height: 16px;
  background: #f1f1f1;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .react-switch-button{
    background: rgba(255, 255, 255, 0.2)
  }
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  transition: 0.2s ease-in-out;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-text {
  font-weight: 400;
  font-size: 14px;
}
