@import '../../common/styles/common.scss';

.report-detail {
  .section-title {
    line-height: 48px;
    background-color: #fff;
    border-width: 1px 0;
    border-style: solid;
    border-color: #EAECF0;
    font-weight: 600;
  }
  &__content {
    .card {
      border: 0;
      &-header {
        border-radius: 0;
        height: 40px;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: #EAECF0;
        background-color: #F9F9F9;
      }
      &-header:nth-child(odd) {
        border-top-width: 0px;
      }
      &-body {
        .stop-record {
          border-width: 0 0 1px;
          border-style: solid;
          border-color: #EAECF0;
          height: 40px;
          padding: 0 32px;
          background-color: #fff;

          &__text,
          &__time {
            font-weight: 400;
            font-size: 12px;
            color: #222222;
            @include textLine(1);
          }
          &__time {
            font-weight: 500;
          }
          &__dot {
            width: 10px;
            height: 10px;
            border-radius: 5px;
          }
          &__header {
            background-color: #DBDEE0 !important;
          }
        }
        .stop-record:nth-child(odd) {
          background-color: #f4f6f7;
        }
      }
    }
  }
}
