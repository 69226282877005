@import '../../../../common/styles/common.scss';

.tooltip-collection {
  display: flex;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  width: 476px;
  padding: 24px 8px 24px 24px;

  .tooltip-inner {
    width: 100%;
    max-width: 100%;
    display: flex;
    background: #fff;
    padding: 0;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #222;
    }

    .collection-table {
      max-height: 450px;
      overflow: hidden;
      margin-top: 12px;
      width: 100%;
      
      .collection-section-header,
      .collection-content {
        p:first-child {
          width: 60%;
        }
        p:not(:first-child) {
          width: 20%;
        }
      }
      .collection-section-header {
        height: 44px;
        border-bottom: 1px solid #e9e9e9;
        p {
          color: #8a8a8a;
        }
      }
      .collection-content {
        max-height: 400px;
        overflow-y: auto;
        @include scrollBars(6px, rgba(76, 105, 127, 0.5), white);

        .item {
          height: 44px;
          border-bottom: 1px solid #e9e9e9;
          align-items: center;
          p {
            color: #222;
            font-size: 14px;
            font-weight: 500;
            @include textLine(1)
          }
        }
      }
    }
  }
}
.tooltip-collection.show {
  opacity: 1;
}
.tooltip-collection .tooltip-arrow::before {
  border-color: white;
}
