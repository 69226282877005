.van-marker {
    bottom: 0;
    width: 14px;
    height: 70px;
    margin-left: -8px;
    cursor: pointer;
    z-index: 3;

  &__dot {
    left: 34%;
    bottom: -1.9px;
    width: 5px;
    height: 5px;
    transform: rotate(-45deg);
  }

  &__box {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-tag {
    display: flex;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    height: 28px;
    padding: 6px 7px;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    border-radius: 4px;

    &__title--short {
      display: block;
    }

    &__title--full {
      display: none;
    }

    &__truck {
      display: none;
    }
  }

  &:hover {
    z-index: 4;
  }

  &:hover &-tag {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 -4px 20px 0 rgba(0, 0, 0, 0.19);
  }

  &:hover &-tag__truck {
    display: block;
  }

  &:hover &-tag__title--short {
    display: none;
  }

  &:hover &-tag__title--full {
    display: block;
  }
}
