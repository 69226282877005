.timeline-chart {
  width: 75%;
  height: 227px;
  padding: 12px 24px;
  margin-bottom: 44px;
  border-radius: 12px;
  background-color: #fff;

  h2 {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }

  .timeline-wrapper {
    width: 100%;
    padding: 0 75px;
  }

  .timeline {
    position: relative;
    margin-top: 100px;
    width: 100%;
    height: 1px;
    background-color: #e9e9e9;
  }

  .timeline .passed-time {
    position: absolute;
    top: 50%;
    transform: translateY(-80%);
    height: 1px;
    border: 1px dashed #F38E1A;
  }

  .pre-time-range,
  .post-time-range {
    position: absolute;
    top: 50%;
    width: 57px;
    transform: translateY(-80%);
    height: 1px;
    border: 1px dashed #E9E9E9;
  }
  
  .pre-time-range {
    left: -60px;
  }
  
  .post-time-range {
    right: -60px;
  }
  
  // Timeline Range and Base Markers
  .timeline ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .timeline .milestones span {
    position: absolute;
    padding-top: 28px;
    margin-left: -22px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    width: 41px;
  }
  
  .timeline .milestones li:nth-child(odd) span:before {
    content: "";
    position: absolute;
    right: auto;
    bottom: 42px;
    left: 50%;
    height: 12px;
    width: 2px;
    background-color: #4c697f;
  }
  
  .timeline .milestones li:nth-child(even) span:before {
    content: "";
    position: absolute;
    bottom: 23px;
    right: auto;
    left: 50%;
    height: 8px;
    width: 1px;
    background-color: #4c697f;
  }
}
