@import '../../common/styles/common.scss';

.container {
  cursor: pointer;
  .arrow-down,
  .arrow-up {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }

  .arrow-up {
    transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  .stop-title,
  .stop-total,
  .stop-num {
    font-size: 12px;
    color: #222222;
  }
  .stop-title {
    font-weight: 600;
    @include textLine(1);
  }
  .stop-total {
    font-weight: 400;
  }
  .dot-indicator {
    min-width: 10px;
    min-height: 10px;
    border-radius: 5px;
  }
  .stop-num {
    font-weight: 500;
  }
}
