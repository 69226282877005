@import '../../../common//styles/common.scss';

.container {
  width: 250px;
  min-height: 183px;
  max-height: 300px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #ffffff;
  right: 16px;
  overflow-y: scroll;
  z-index: 9999;

  .header-title {
    font-weight: 500;
    font-size: 16px;
  }
  .section-title {
    font-weight: 400;
    font-size: 14px;
  }

  .checkbox-list {
    margin-top: 8px;
    padding: 12px;
    max-height: 177px;
    background-color: #F7F9FB;
    border-radius: 8px;
    overflow-y: scroll;
    @include scrollBars(5px, rgba(76, 105, 127, 0.5), rgba(255, 255, 255, 0));

    .checkbox-item {
      margin-top: 12px;
    }

    .checkbox-item:first-child {
      margin-top: 0;
    }
  }
  
  @include scrollBars(5px, rgba(76, 105, 127, 0.5), rgba(255, 255, 255, 0));
}
