@import '../../../common/styles/common.scss';

.container {
  height: 100%;
  .header {
    height: 48px;
    img {
      cursor: pointer;
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      color: #222222;
      @include textLine(1);
    }
  }

  .content {
    height: calc(100% - 48px); // 48px of header completed at ....
    overflow-y: auto;
    .header {
      span,
      p {
        font-size: 12px;
        color: #fff;
      }
      span {
        font-weight: 600;
      }
      p {
        font-weight: 400;
      }
    }
    .line-horizontal {
      height: 4px;
      width: 100%;
      background-color: #6B8ADB;
    }
  }
  .status {
    font-weight: 500;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    width: fit-content;
  }
  .driver-info {
    background-color: #f9f9f9;
    &__header {
      font-weight: 600;
      font-size: 12px;
      color: #222222;
    }
    &__label {
      font-weight: 500;
      font-size: 10px;
      color: #98a1b5;
    }
    &__text {
      font-weight: 500;
      font-size: 12px;
      color: #101828;
    }
  }
  .stop-info {
    background-color: #fff;
    &__header {
      font-weight: 600;
      font-size: 12px;
      color: #222222;
    }
    .stop-info__section {
      margin-top: 12px;
    }
    &__label {
      font-weight: 500;
      font-size: 12px;
      color: #98a1b5;
    }
    &__text {
      font-weight: 500;
      font-size: 12px;
      color: #101828;
    }
  }
}
