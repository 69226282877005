@mixin scrollBars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: calc($size / 2);
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: calc($size / 2);
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin textLine($numberOfLines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines; /* number of lines to show */
  line-clamp: $numberOfLines;
  -webkit-box-orient: vertical;
}
