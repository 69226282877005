@import '../../../common/styles/common.scss';

.modal-content {
  .header {
    height: 60px;
    &__title {
      font-weight: 700;
      font-size: 24px;
      color: #101828;
    }
  }
  .modal-body {
    .hospital-section-text {
      font-weight: 600;
      font-size: 24px;
      color: #4c697f;
    }
    .section-text {
      font-weight: 600;
      font-size: 18px;
    }
    .dropdown-container {
      width: 336px;
    }

    .arrival-table-wrapper {
      height: 830px;
      padding: 24px;
      border-radius: 12px;
      margin-bottom: 44px;

      h2 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        margin-bottom: 18px;
      }
    }

    .arrival-table {
      height: 726px;
      border-radius: 12px;
      border: 1px solid #E9E9E9;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;

      .expaneded-row-head,
      th {
        height: 64px;
        background-color: #f9f9f9;
        text-align: center;
        vertical-align: middle;
        font-weight: 500;
        font-size: 14px;
        color: #8a8a8a;

        &:first-child,
        &:last-child {
          text-align: left
        }
      }
    }

    td,
    th {
      max-width: calc((0.75 * 100vw - 48px) / 7);
      min-width: calc((0.75 * 100vw - 48px) / 7);
    }

    thead tr {
      display: block;
      position: relative;
    }

    tbody {
      display: block;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      text-align: center;

      .clickable-row {
        cursor: pointer;
      }

      tr {
        height: 64px;
        font-weight: 600;
        color: #222;
        font-size: 14px;
        vertical-align: middle;
        border: 1px solid #e9e9e9;
      }

      .arrow-up,
      .arrow-down {
        width: 14px;
        height: 14px;
        transition: all 0.5s ease-in-out;
      }

      .arrow-up {
        transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }

    .expanded-row-content {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease-in-out;

      &.expanded {
        max-height: 100vh;
      }
    }

    .expaneded-row-head {
      background-color: #E9E9E9 !important;

      td {
        text-align: center;
      }

      td:last-child {
        text-align: left
      }
    }
    
    .expaneded-row-head td:first-child,
    .expaneded-row-body td:first-child {
      padding-left: 44px;
      text-align: left;
    }
    .expaneded-row-body td{
      background-color: #F9F9F9;
    }

    .hight-light,
    .hight-light--long-text {
      display: inline-block;
      min-width: 84px;
      height: 32px;
      padding: 6px 16px;
      background: rgba(253, 101, 97, 0.35);
      border-radius: 100px;
    }

    .hight-light--long-text {
      margin-left: -16px;
    }

    .total-collection-cell {
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(76, 105, 127, 0.5);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #627583;
  }
}
