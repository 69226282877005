@import '../../common/styles/common.scss';

.card {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 336px;
  min-width: 336px;
  overflow: hidden;
  border: 3px solid #fff;

  &:not(:first-child) {
    margin-left: 32px;
  }

  .top-content {
    margin: 0 16px;
    .card-header {
      height: 56px;
      &__name {
        font-weight: 700;
        font-size: 16px;
        color: #222222;
        overflow: hidden;
        line-height: 23px;
        @include textLine(1);
      }
      &__mark-color {
        min-width: 16px;
        min-height: 16px;
        border-radius: 2px;
      }
      .live-tracking__label {
        font-weight: 500;
        font-size: 14px;
      }
      .live-tracking__dot {
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }

    .card-break-line {
      border-top: 1px solid #eaecf0;
    }
  }

  &-info {
    margin: 12px 0;
    &__label {
      font-weight: 400;
      font-size: 14px;
      color: #bebebf;
    }
    &__value {
      font-weight: 500;
      font-size: 14px;
      color: #222222;
    }
    &__content {
      margin: 16px 10px 16px 16px;
      overflow-y: scroll;
      height: 100%;
      padding-right: 3px;
      @include scrollBars(4px, #fff, #fff);
    }
    &__content:hover {
      @include scrollBars(4px, #4c697f, #eaecf0);
    }
  }
  &:hover {
    border-color: #4c697f;
  }
}

.tooltip-container {
  position: relative;
  opacity: 1 !important;
  p {
    color: #000;
  }

  div[class^='tooltip-arrow']::before {
    display: none;
  }
  div[class^='tooltip-inner'] {
    display: flex !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background-color: white;
    width: 336px;
    max-width: 336px;
    min-height: 188px;
    padding: 16px;
  }
  .tooltip-title {
    font-size: 16px;
    font-weight: 500;
  }
  .tooltip-label {
    font-weight: 400;
    font-size: 14px;
    color: #bebebf;
  }
  .tooltip-value {
    font-weight: 500;
    font-size: 14px;
    color: #222222;
  }
}
