.container {
  img {
    width: 72px;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #101828;
  }

  &.large {
    img {
      width: 150px;
    }

    p {
      font-size: 20px;
    }
  }
}