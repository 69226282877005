.van-group-marker {
  bottom: 0;
  width: 28px;
  height: 36px;
  margin-left: -15px;
  cursor: pointer;
  z-index: 3;

  &__dot,
  &__tag {
    background-color: #222
  }

  &__dot {
    left: 43%;
    bottom: -1.9px;
    width: 5px;
    height: 5px;
    transform: rotate(-45deg);
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
  }

  &__popup {
    display: none;
    position: absolute;
    top: 42px;
    width: 252px;
    max-height: 236px;
    padding: 0px 3px 16px 8px;
    border-radius: 8px;
    background: #FFF;
    box-shadow:  0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    overflow-y: scroll;
    cursor: default;
  }

  &:hover {
    z-index: 4;
  }

  &:hover &__popup {
    display: flex;
  }

  &__item { 
    display: flex;
    padding: 6px 7px;
    border-radius: 4px;
  }

  &__item span {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #FFF;
    font-weight: 400;
    white-space: nowrap;
  }
}
