
.liaison-container {
  min-height: 100vh;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
}

.bg-dark {
  background-color: #212121 !important;
}

.card-img {
  width: auto;
  padding: 10px;
}

.navbar {
  padding: 20px;
}

iframe {
  width: 100%;
  width: -webkit-fill-available;
}

.navbar-dark .navbar-nav .nav-link {
  font-weight: bold;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1em;
  color: rgba(255, 255, 255, 0.9);
  padding: 10px 13px;
}

a:hover {
  color: cornflowerblue;
}

.card > a:hover {
  color: blue;
  text-decoration: none;
}

#dashboard > .card:hover {
  background-color: lightgray;
}

.App-logo {
  height: auto;
  max-height: 100px;
  width: 140px;
  max-width: 100%;
}

.typeahead {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 500px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 500px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 350px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

@media only screen and (max-width: 768px) {
  .react-autosuggest__input {
    width: -webkit-fill-available;
    height: auto;
  }

  .react-autosuggest__suggestions-container--open {
    width: auto;
  }

  /*Allow the typeahead to go to the top of screen on mobile so that the dropdown fills the screen with less scrolling.*/
  .loading {
    padding-top: 10px;
    min-height: 0;
    align-items: inherit;
  }
}

.card-format {
  height: 320px;
  width: 30%;
  float: left;
  margin: 10px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.ps-sidebar-root.ps-collapsed {
  min-width: 60px !important;
  width: 60px !important;
}

.ps-menu-label {
  white-space: unset !important;
}

.ps-submenu-content {
  width: auto !important;
  max-width: 280px !important;
}

.ps-submenu-content .ps-menu-button {
  padding-left: unset !important;
  /* padding-right: unset !important; */
}

.ps-submenu-expand-icon span {
  width: 7px;
  height: 7px;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  /* padding: 20px; */
  margin-left: 0;
  height: 100vh;
  overflow: auto;
  padding: unset !important;
}

@media only screen and (max-width: 575.98px) {
  body {
    overflow:  hidden;
  }
  
  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }
  
  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }
  
  .sidebar.is-open > .sidebar-header button {
    display: block;
  }
  
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

.dropdown-container {
  font-size: 14px !important;
  border: 1px solid #EAECF0 !important;
  max-height: 38px !important;
  border-radius: 5px !important;
}

.dropdown-container:focus-within {
  /* border: 1px solid #EAECF0 !important; */
  box-shadow: unset !important;
}

.dropdown-heading {
  height: 33px !important;
}

.react-datepicker {
  border: 1px solid #E9E9E9 !important;
}

.react-datepicker__header {
  border-bottom: 1px solid #E9E9E9 !important;
}
.react-datepicker__navigation-icon::before {
  top: 10px !important;
}

.dropdown-content .options .select-item .item-renderer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}