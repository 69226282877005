.clock-marker {
  margin-left: -9px;
  cursor: pointer;
  z-index: 2;

  &__dot {
    top: -2.2px;
    left: 38%;
    transform: translateX(-50%);
    width: 5px;
    height: 5px;
    background-color: #F38E1A;
    transform: rotate(-45deg);
  }

  &__time {
    display: none;
    top: 40%;
    left: 120%;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #F38E1A;
  }

  &:hover &__time {
    display: block;
  }
}
